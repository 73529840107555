import React, { useState }  from "react";
import axios from 'axios';

import { EventSettingsContext } from "./contexts/eventsettings";
import { MyRegistrationContext } from "./contexts/myregistration.js";

import logo from "./assets/logo.png"; 

import './Mast.css';

import AccountSetup from './AccountSetup.js'
import BasicInfo from './BasicInfo.js'
import TermsAndConditions from './TermsAndConditions.js'
import Payment from './Payment.js'
import Container from 'react-bootstrap/Container';


function App(props){ 
	const [myRegistration, setMyRegistration] = useState({});
	const [eventSettings, setEventSettings] = useState({key:'pitm2024',startDate:new Date('2024-09-27'),name:'PITM 2024',paymentsDueDate:new Date('2024-08-31')});

	const [user,setUser] = useState({}); 
	const [authToken,setAuthToken] = useState(null); 
	const [showAccountSetup,setShowAccountSetup] = useState(true); 
	const [showBasicInfo,setShowBasicInfo] = useState(false); 
	const [showTerms,setShowTerms] = useState(false); 
	const [showPayment,setShowPayment] = useState(false); 
	
	function storeAccountDataAndContinue(email,password){ 
		setUser({"email":email,"password":password}); 
		setShowAccountSetup(false); 
		setShowBasicInfo(true); 
	} 
	
	function saveRegistration(reg){ 
		setMyRegistration(reg); 
		setShowBasicInfo(false); 
		setShowTerms(true); 
	}
	
	function createRegistration(token){ 
		var regToSave = myRegistration; 
		
		myRegistration.profile.sceneName = null;
		regToSave.guest.firstName = null;
		regToSave.guest.lastName = null;
		regToSave.guest.email = null;
		
										axios.post(process.env.REACT_APP_API_HOST+"/registrations",regToSave,{headers: {'Bearer': token}})
													.then(result => {
																	if (result.status === 200) {

																		setShowTerms(false); 
																		setShowPayment(true); 
																		setMyRegistration(result.data); 

																	} else {
// 																		setIsLoadRegistrationError(true); 
																	}
																}).catch(e => {
// 																	setIsLoadRegistrationError(true); 
																});										
										
	} 
	function storeTermsAgreement(){ 
		
		var today = new Date(); 
		var month = today.getMonth()+1; 
		
		myRegistration.termsAgreedDate = today.getFullYear()+'-'; 
		if(month<10) myRegistration.termsAgreedDate+='0'
		myRegistration.termsAgreedDate+=month+'-'; 
		if(today.getDate()<10) myRegistration.termsAgreedDate+='0'; 
		myRegistration.termsAgreedDate+=today.getDate(); 

		setMyRegistration(myRegistration); 
		
		var createUserPayload = {"sceneName":myRegistration.profile.sceneName,
													"firstName":myRegistration.guest.firstName,
													"lastName":myRegistration.guest.lastName,
													"email":user.email,
													"password":user.password}; 
		
		
		axios.post(process.env.REACT_APP_API_HOST+"/users",createUserPayload)
      		.then(result => {
									if (result.status === 200) {
										
										var user_id = result.data.id; 
										myRegistration.guest.id = user_id; 
										setMyRegistration(myRegistration); 

										axios.post(process.env.REACT_APP_API_HOST+"/auth/login",{"email":user.email,"password":user.password})
													.then(result => {
																	if (result.status === 200) {
																		setAuthToken(result.data.token); 
																		createRegistration(result.data.token); 
																	} else {
								// 										setIsLoadRegistrationError(true); 
																	}
																}).catch(e => {
								// 									setIsLoadRegistrationError(true); 
																});


									} else {
// 										setIsLoadRegistrationError(true); 
									}
								}).catch(e => {
// 									setIsLoadRegistrationError(true); 
								});
		
		
	} 
	
	return (
		<MyRegistrationContext.Provider value={{ myRegistration, setMyRegistration:saveRegistration }}>
		<EventSettingsContext.Provider value={{ eventSettings, setEventSettings }}>
				<div className="content">
					<header>
						<img src={logo} alt="" width="300"/>
						<h2>Register for Puppies in the Mountains!</h2>
					</header>

					
			    <Container>

                    <p style={{"textAlign":"center"}}>Registration for Puppies in the Mountains has closed. Please keep an eye out for when we open registration for next year's event!</p>
{/* 
					<p style={{"textAlign":"center"}}>Squeeeak! Puppies in the Mountains is at capacity! We're going to have the biggest year ever!</p>
					<p style={{"textAlign":"center"}}>While we are currently sold out, we usually have cancellations. Put your name on the waiting list and 
                    if we have spots open up, you'll be invited to register. Please note that if we are able to invite you to register, you will have THREE DAYS from when 
                    we contact you to register. If you don't get back to us, we will go to the next people on the list. Depending on how close to the event we are, you may be 
                    required to pay the full registration price ($400 per person). If you travel with your significant other(s), you can note how many are in your party. 
                    (The others don't need to join the wait list).  </p>
                    <p style={{"textAlign":"center"}}><a href="https://docs.google.com/forms/d/e/1FAIpQLSc5QcBbkFmEaXwf7mZxnkwPJ8GIwedAd842os7U2C4ebwVSMQ/viewform?usp=sf_link">Click here to join the wait list</a></p>
             */}
                </Container>
       
					{/* { showAccountSetup?<AccountSetup onComplete={storeAccountDataAndContinue} />:''}
					{ showBasicInfo? <BasicInfo />:''}
					{ showTerms?<TermsAndConditions onAgreement={storeTermsAgreement} />:''}
					{ showPayment?<Payment authToken={authToken} />:''}
                
					 */}
				</div>
		</EventSettingsContext.Provider>
		</MyRegistrationContext.Provider>
    );
}
 
export default App;