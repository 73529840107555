import React, {useState, useEffect} from "react";
import axios from 'axios';
import { useEventSettings } from "./contexts/eventsettings";
import { useMyRegistration } from "./contexts/myregistration.js";


import { FormCard, FormLabel, SubmitButton, SaveSpinnerContainer } from "./styled-components/Forms.js";

import Form from 'react-bootstrap/Form'
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Spinner from 'react-bootstrap/Spinner'
import Alert from 'react-bootstrap/Alert'

import './custom-widgets/Switch.css'; 


function AccountSetup(props) {
	const { myRegistration, setMyRegistration } = useMyRegistration(); 
  const { eventSettings } = useEventSettings();
	const [isCheckingForUser, setIsCheckingForUser] = useState(false);
	const [isReady, setIsReady] = useState(false);
	const [passwordMismatch, setPasswordMismatch] = useState(false);
	const [registeredAsCarryover, setRegisteredAsCarryover] = useState(false);
	const [alreadyRegistered, setAlreadyRegistered] = useState(false);

	const successCallback = props.onComplete; 
	
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	
	function handleFieldChange(id,val){  
		if(id==='email') setEmail(val); 
		if(id==='password') setPassword(val); 
		if(id==='confirmpassword') setConfirmPassword(val); 
		if(email!='' && password!='' && confirmPassword!=''){ 
				setIsReady(true); 
		}
	} 
	
	function savePublicProfile(){ 
			if(password==confirmPassword){ 
				setPasswordMismatch(false);
				setIsCheckingForUser(true); 
				
		axios.post(process.env.REACT_APP_API_HOST+"/usercheck",{"email":email})
      		.then(result => {
									if (result.status === 200) {
										
										if (result.data.status=="not-found"){ //is new registration
											successCallback(email,password); 
											
										} else if(result.data.status=="ok"){ 
											if(result.data.carryover){ 
												setRegisteredAsCarryover(true); 
												setIsCheckingForUser(false); 
											} else { 
												setAlreadyRegistered(true); 
												setIsCheckingForUser(false); 
											}
									} else {
// 										setIsLoadRegistrationError(true); 
									}
								}
		}).catch(e => {
// 									setIsLoadRegistrationError(true); 
								});
				
				
			} else { 
				setPasswordMismatch(true); 
			setIsCheckingForUser(false); 
			} 
	} 
	
    return (<>
			<Container className="form-cards">
				<Row>
					<Col md={12}>
						{ alreadyRegistered ? <Alert variant="success">You are already registered for PITM 2024! If you have any questions please <a href="mailto:webmaster@puppiesinthemountains.com">email us</a>.</Alert>: ''}
						{/* { registeredAsCarryover ? <Alert variant="success">Your registration has been carried over from our cancelled 2020 event. There is nothing else for you to do right now. 
                        We will soon open our brand-new My Experience app, where you will be able to update your profile and registration information. If you have any questions in the meantime, please <a href="mailto:webmaster@puppiesinthemountains.com">email us</a>.</Alert>: */} 
						{!alreadyRegistered && !registeredAsCarryover ?
						<FormCard id="" className="red">
							<Card.Header as="h5">Email and Password</Card.Header>
							<Card.Body>
								<Row>
								<Col md={12} lg={6}>
									<Form.Row>
										<p>Let's start by creating your account. Enter your email address and choose a password. You'll receive notices, updates, and alerts at this address and use it to sign into My Experience.</p>
										<br />
									</Form.Row>
								</Col>
								<Col md={12} lg={6}>
									<Form.Row>
									<Form.Group as={Col}>
											<FormLabel.Primary>Email Address</FormLabel.Primary>
											<Form.Control type="email" placeholder="" id="email" value={email} onChange={e => { handleFieldChange(e.target.id, e.target.value); }}/>
										</Form.Group>
									</Form.Row>
									<Form.Row>
									<Form.Group as={Col}>
											<FormLabel.Primary>Password</FormLabel.Primary>
											<Form.Control type="password" placeholder="" id="password" value={password} onChange={e => { handleFieldChange(e.target.id, e.target.value); }}/>
										</Form.Group>
									</Form.Row>
									<Form.Row>
									<Form.Group as={Col}>
											<FormLabel.Secondary>Confirm Password</FormLabel.Secondary>
											<Form.Control type="password" placeholder="" id="confirmpassword" value={confirmPassword} onChange={e => { handleFieldChange(e.target.id, e.target.value); }}/>
										</Form.Group>
									</Form.Row>
									{ passwordMismatch ? <Form.Row>
										<Alert variant="danger">Passwords don't match.</Alert>
										</Form.Row> :''}
									<Form.Row>
						{isCheckingForUser ?  
						 <SaveSpinnerContainer>
							<Spinner animation="grow" variant="primary" />&nbsp;
							<Spinner animation="grow" variant="secondary" />&nbsp;
							<Spinner animation="grow" variant="success" />&nbsp;
							<Spinner animation="grow" variant="danger" />&nbsp;
							<Spinner animation="grow" variant="warning" />&nbsp;
							<Spinner animation="grow" variant="info" />
						 </SaveSpinnerContainer>
							:
										<SubmitButton value="Next" disabled={ (isReady)?false:true } onClick={e => { savePublicProfile(); }}  />
										}
										</Form.Row>
								</Col>
								</Row>
							</Card.Body>
						</FormCard> : ''}
					</Col>
				</Row>
			</Container>
</>
    );
}
 
export default AccountSetup;